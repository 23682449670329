import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "countdown"]

  connect() {
    let dialog = this.modalTarget.closest("dialog")

    dialog.showModal()
  }

  close() {
    this.modalTarget.closest("dialog").close()
  }

}