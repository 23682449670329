import Hammer from "hammerjs"

let touchInputClass

if (Hammer.SUPPORT_POINTER_EVENTS) {
  touchInputClass = Hammer.PointerEventInput
} else {
  touchInputClass = Hammer.TouchInput
}

export default class {
  constructor(element) {
    this.hammer = Hammer(element, {
      inputClass: touchInputClass,
    })
  }

  destroy() {
    this.hammer.destroy()
  }

  handleEventsFor(svgPanZoom) {
    let initialScale = 1
    let pannedX = 0
    let pannedY = 0

    this.hammer.get("pinch").set({
      enable: true,
    })

    this.hammer.on("doubletap", () => {
      svgPanZoom.zoomIn()
    })

    this.hammer.on("panstart panmove", ({ type, deltaX, deltaY }) => {
      if (type === "panstart") {
        pannedX = 0
        pannedY = 0
      }

      svgPanZoom.panBy({
        x: deltaX - pannedX,
        y: deltaY - pannedY,
      })

      pannedX = deltaX
      pannedY = deltaY
    })

    this.hammer.on("pinchstart pinchmove", ({ center, scale, type }) => {
      if (type === "pinchstart") {
        initialScale = svgPanZoom.getZoom()
      }

      svgPanZoom.zoomAtPoint(initialScale * scale, {
        x: center.x,
        y: center.y,
      })
    })
  }

  get eventsListenedFor() {
    return [
      "touchstart",
      "touchend",
      "touchmove",
      "touchleave",
      "touchcancel",
    ]
  }
}
