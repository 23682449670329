export default class {
  constructor({ element, attributeName }) {
    this.element = element
    this.attributeName = attributeName
    this.value = element.getAttribute(attributeName)
  }

  add(item) {
    this.set.add(item)

    this.serializeToAttribute()
  }

  remove(item) {
    this.set.delete(item)

    this.serializeToAttribute()
  }

  set value(value) {
    let tokens = value.toString().split(" ")
    this.set = new Set(tokens)

    this.serializeToAttribute()
  }

  get value() {
    return this.asArray.join(" ")
  }

  // ---

  serializeToAttribute() {
    this.element.setAttribute(this.attributeName, this.value)
  }

  get asArray() {
    return Array.from(this.set)
  }
}
