import { Controller } from "stimulus"

let merch = new Object(null);
export default class extends Controller {
    static targets = [ 'componentPath', 'userForm', 'paymentPlanForm', 'checkoutForm' ]

    connect() {
        const component = this.componentPathTarget;
        renderComponentPath(component)
        document.querySelector('.simple-donation-amount').addEventListener('keyup', (event) => {
            const thisUrl = '/donate-now/payments/payment_component?calculated_amount=' + document.querySelector('input.simple-donation-amount').value;
            renderPaymentComponent(thisUrl);
        });
        document.querySelector('.tms-donate-now').addEventListener('click', (event) => {
            event.preventDefault();
            const loader = document.querySelector('.loader');
            event.target.disabled = true;
            loader.classList.add('ajax-loader');
            submitAddToCartContribution();
        })
        const elementToObserve = document.querySelector("#payment-component");
        const observer = new MutationObserver(handleError);
        observer.observe(elementToObserve, { subtree: true, childList: true });
    }
}

function handleError(mutations) {
    const button = document.querySelector('.tms-donate-now');
    const loader = document.querySelector('.loader');
    for (let m of mutations) {
        if (m.type === 'childList' && m.addedNodes.length > 0) {
            button.disabled = false;
            loader.classList.remove('ajax-loader');
        }
    }
}

function renderPaymentComponent(url) {
    fetch(url)
        .then(response => response.json())
        .then(function (data) {
            if (data.Payload === null) {
                const error = "We're sorry, our credit card provider is unavailable at this time. Please try again later. We apologize for the inconvenience."
                renderError(error);
            } else {
                const payload = data.Payload;
                const paymentComponent = document.querySelector('#payment-component');
                merch = new TessituraMerchantServices.PaymentComponent({
                    "payload": payload,
                    "showPayButton": false,
                    "enableStoredCards": true,
                    "endpoints": {
                        "authorize": "/donate-now/payments/payment_auth",
                        "finalize": "/donate-now/payments/finalize"
                    },
                    "onChange": (state, component) => {
                        console.dir({ state });
                    },
                    "locale": "en-US",
                });
                paymentComponent.innerHTML = "";
                merch.mount(paymentComponent);
            }
        })
}

function renderComponentPath(component) {
    let url = '/donate-now/payments/payment_component?calculated_amount=0'
    fetch(url)
        .then(response => response.json())
        .then(function (data) {
            component.src = data.Url;
            component.integrity = data.Checksum;
            renderPaymentComponent(url)
        })
}

function submitAddToCartContribution() {
    const userForm = document.querySelector('#user_address_form')
    submitForm(userForm, submitLogin);
}

function submitLogin() {
    const paymentPlanForm = document.querySelector('#payment-plan-form')
    submitForm(paymentPlanForm, submitCheckout);
}

function submitCheckout() {
    const checkoutForm = document.querySelector('#payments-form');
    submitForm(checkoutForm, submitMerch);
}

function submitMerch() {
    const paymentComponentRendered = document.querySelector('#payment-component');
    const button = document.querySelector('.tms-donate-now');
    const loader = document.querySelector('.loader');
    if (paymentComponentRendered.innerHTML.length === 0) {
        const error = document.querySelector('.simple-donation-amount');
        error.classList.add('error');
        error.placeholder = "This field is required";
        error.focus();
        button.disabled = false;
        loader.classList.remove('ajax-loader');
        return false;
    } else {
        merch.submit();
    }
}

function submitForm(form, submitFunction) {
    let isValid = validateForm(form);
    const button = document.querySelector('.tms-donate-now');
    const loader = document.querySelector('.loader');
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const path = form.action;
    if (!isValid) {
        button.disabled = false;
        loader.classList.remove('ajax-loader');
        return false;
    }

    fetch(path, {
        method: "POST",
        headers: {
            "X-CSRF-Token": csrfToken,
        },
        body: new FormData(form)
    })
        .then(handleErrors)
        .then(response => response.text())
        .then((data) => {
            submitFunction(data);
        })
        .catch(function (error) {
            console.log(error);
            const errorMsg = "We apologize, there was a problem processing the request. Please refresh the page and try again."
            renderError(errorMsg);
        });
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

function validateForm(form) {
    let isValid = true;

    let requiredFieldSelectors = 'input:required';
    let requiredFields = form.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
        if (!field.disabled && !field.value.trim()) {
            field.focus();

            isValid = false;
            return false;
        }
    });
    let invalidFields = form.querySelectorAll('input:invalid');
    invalidFields.forEach((field) => {
        if (!field.disabled) {
            field.classList.add('error');
            field.placeholder = `This field is required.`
            isValid = false;
        }
    });

    return isValid;
}

function renderError(error) {
    const div = document.createElement('div');
    const paymentComponent = document.querySelector('#payment-component');
    const existingError = document.querySelector('.paymentComponent.error');
    div.className = 'paymentComponent error';
    div.innerHTML = error;
    if (existingError !== null) {
        paymentComponent.removeChild(existingError);
    }
    paymentComponent.append(div);
}