import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    if( window.matchMedia("(min-width: 60em)").matches ) {
      document.getElementsByClassName("syos-floor-nav--with-map")[0].classList.add("is-expanded")
      this.buttonTarget.setAttribute("aria-checked", "true")
    }
  }

  toggle() {
    let checked = this.buttonTarget.getAttribute("aria-checked")
    this.element.classList.toggle("is-expanded")
    this.buttonTarget.setAttribute("aria-checked", checked !== "true")
  }
}
