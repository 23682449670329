import { Controller } from "stimulus"

export default class extends Controller {
  navigationStarted() {
    this.element.classList.add("is-loading")
  }

  navigationFinished() {
    this.element.classList.remove("is-loading")
  }
}
