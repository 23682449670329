import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['performance', 'time']
    renderZones(event) {
        event.preventDefault();
        const url = event.target.href;
        const container = document.querySelector('.time-zone');
        const spinner = document.querySelector('.loader');
        spinner.classList.add('ajax-loader');
        container.innerHTML = '';

        this.performanceTargets.forEach((t) => {
            t.classList.remove('is-selected');
        });
        event.target.classList.add('is-selected');
        renderTimesContainer(url);
    }

    selectTime(event) {
        this.timeTargets.forEach((t) => {
            t.classList.remove('is-selected')
        })
        event.target.closest('label').classList.add('is-selected');
        document.querySelector('.information').classList.remove('visually-hidden');
    }

}

function renderTimesContainer(url) {
    const container = document.querySelector('.time-zone');
    const spinner = document.querySelector('.loader');

    fetch(url)
        .then(response => response.json())
        .then(function (data) {
            let html = '';
            data.forEach(zone => {
                let zoneSection = `<div class="time-zones zone_${zone.id}">
                                        <label 
                                        data-timed-entry-target="time"
                                        data-action="click->timed-entry#selectTime"
                                        for="zone_${zone.id}">${zone.description}
                                        <input type="radio" id="zone_${zone.id}" name="zone_id" value="${zone.id}" />
                                        </label>
                                       </div>`;
                html += zoneSection;
            })
            spinner.classList.remove('ajax-loader');
            container.innerHTML = html;
            document.querySelector('.pick-a-time h3').style.display = 'block';
        })
}

const remainingText = (remaining_seats) => {
    if (remaining_seats === 1) {
        return "1 ticket available"
    } else {
        return `${remaining_seats} tickets available`
    }
}