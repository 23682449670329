/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "whatwg-fetch"
import "@webcomponents/template"
import "@webcomponents/html-imports"
import "@webcomponents/custom-elements"
import "@webcomponents/webcomponents-platform"
import "include-fragment-element"
import "polyfills/svg-element/dataset"
import "polyfills/child-node/remove"

import "core-js/es/reflect/construct"
import "core-js/es/array/includes"
import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Popover from 'stimulus-popover'


import Rails from "rails-ujs"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
application.register('popover', Popover)

Rails.start()

