import { Controller } from "stimulus"

function sendEventToDataLayer(event, dlVar, label) {
  dataLayer.push({
    'event': event,
    [dlVar] : label
  });
}

export default class extends Controller {
  static targets = ["modal"]

  load(event) {
    let dialog = this.modalTarget.closest("dialog")

    dialog.showModal()

    event.preventDefault()

    sendEventToDataLayer("changeDate", "changeDate", "SYOSv2 | Change Date")
  }

}
