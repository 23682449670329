import { Controller } from "stimulus"
import Moment from "moment"
import Timer from "tiny-timer"

function sendEventToDataLayer(event, dlVar, label) {
  dataLayer.push({
    'event': event,
    [dlVar] : label
  });
}

export default class extends Controller {
  static targets = ["modal", "countdown"]

  connect() {
    let countdown = this.countdownTarget
    let timer = new Timer()

    timer.on('tick', () => {
      countdown.hidden = false
      let asUtc = Moment.utc(this.duration.asMilliseconds())
      let formatted = asUtc.format("mm:ss")

      countdown.innerHTML = formatted
    })
    timer.on('done', () => this.expiredModal())

    timer.start(this.duration.asMilliseconds())
  }

  get duration() {
    let cartTime = Moment(this.countdownTarget.dateTime)
    let now = Moment()
    let difference = cartTime.diff(now)
    return Moment.duration(difference)
  }

  expiredModal() {
    let dialog = this.modalTarget.closest("dialog")
    dialog.showModal()
    sendEventToDataLayer("cartTimerExpired", "cartTimerExpired", "SYOSv2 | Cart Timer Expired")
  }

}

