import { Controller } from "stimulus"
import svgPanZoom from "svg-pan-zoom"
import TouchEventHandler from "../touch_event_handler"
import TokenList from "../token_list"

function getSeatId(element) {
    return element.dataset.seatId
}

function selectSeat(seat) {
    let { selectedLabel, unselectedLabel, selectedSymbol } = seat.dataset
    let labels = labelsFor(seat)
    const vipSeat = seat.dataset.zone.includes("VIP") ? "| VIP Seat" : ""

    labels.add(selectedLabel)
    labels.remove(unselectedLabel)

    seat.setAttribute("xlink:href", selectedSymbol)

    sendEventToDataLayer("clickSeat", "clickSeat", "Click Seat | Select " + vipSeat)
}

function unselectSeat(seat) {
    let { selectedLabel, unselectedLabel, unselectedSymbol } = seat.dataset
    let labels = labelsFor(seat)
    const vipSeat = seat.dataset.zone.includes("VIP") ? "| VIP Seat" : ""

    labels.remove(selectedLabel)
    labels.add(unselectedLabel)

    seat.setAttribute("xlink:href", unselectedSymbol)

    sendEventToDataLayer("clickSeat", "clickSeat", "Click Seat | Unselect " + vipSeat)
}

function labelsFor(seat) {
    let anchor = seat.closest("a")

    return new TokenList({
        element: anchor,
        attributeName: "aria-labelledby",
    })
}

function sendEventToDataLayer(event, dlVar, label) {
    dataLayer.push({
        'event': event,
        [dlVar]: label
    });
}

export default class extends Controller {
    static targets = [
        "addSeatToCartErrors",
        "cartSummaryItem",
        "cartSummary",
        "drawer",
        "filters",
        "interactiveSeat",
        "legendItem",
        "map",
        "modal",
        "seat"
    ]

    connect() {
        this.svgMap = svgPanZoom(this.mapTarget, {
            center: true,
            fit: true,
            zoomEnabled: true,
            zoomScaleSensitivity: 0.75,
            minZoom: 1.0,
            maxZoom: 8,
            customEventsHandler: this.eventsHandler,
        })

        this.selectedSeats.forEach(selectSeat)
        this.synchronizeLegendWithMap()
        Turbolinks.clearCache()
    }

    zoomIn() {
        this.svgMap.zoomIn()
        sendEventToDataLayer("clickZoom", "zoomValue", "Zoom In")
    }

    zoomOut() {
        this.svgMap.zoomOut()
        sendEventToDataLayer("clickZoom", "zoomValue", "Zoom Out")
    }

    choose(event) {
        let anchor = event.currentTarget
        let seat = anchor.querySelector("[data-seat-id]")
        let seatUrl = anchor.attributes["xlink:href"].value
        let seatName = '';
        let seatType = seat.dataset.holdType
        switch (seatType) {
            case 'devo_seat': {
                seatName = ' | Devo Seat'
                break
            }
            case 'sign language interpreter': {
                seatName = ' | ASL Seat'
                break
            }
            case 'partial view' : {
                seatName = ' | Partial View Seat'
                break
            }
            case 'wheelchair_seat': {
                seatName = ' | Wheelchair Seat'
                break
            }
            case 'wheelchair_companion': {
                seatName = ' | Wheelchair Companion'
                break
            }
            default: {
                seatName = ''
            }
        }

        if (this.selectedSeats.includes(seat)) {
            seatUrl = anchor.dataset.selectedUrl || seatUrl
        }

        let dialog = this.modalTarget.closest("dialog")

        this.modalTarget.innerHTML = `
      <include-fragment src="${seatUrl}">
    `

        dialog.showModal()
        if (seatType !== 'undefined') {
            sendEventToDataLayer("clickSeat", "clickSeat", "Click Seat" + seatName)
        }
        event.preventDefault()
    }

    select() {
        this.selectedSeats.forEach(selectSeat)
        this.synchronizeLegendWithMap()

        this.modalTarget.closest("dialog").close()

        sendEventToDataLayer("selectSeat", "selectSeat", "Select Seat")
    }

    unselect({ target }) {
        let seatElement = target.closest("[data-seat-id]")

        this.selectedSeats.filter(seat => getSeatId(seatElement) == getSeatId(seat)).forEach(unselectSeat)

        this.cartSummaryItemTargets.filter(row => getSeatId(row) === getSeatId(seatElement)).forEach(row => row.remove())

        this.synchronizeLegendWithMap()

        sendEventToDataLayer("selectSeat", "selectSeat", "Remove Seat")
    }

    addSeatToCartError(event) {
        let [ response ] = event.detail
        this.addSeatToCartErrorsTarget.hidden = false
        this.addSeatToCartErrorsTarget.innerHTML = response.body.innerHTML
    }

    clearError() {
        this.addSeatToCartErrorsTarget.hidden = true
        this.addSeatToCartErrorsTarget.innerHTML = ""
    }

    clearCartSummary() {
        this.cartSummaryItemTargets.forEach(row => row.remove())
        document.querySelector("#cart-summary-items").insertAdjacentHTML(
            "beforeend",
            "<tr></tr><td>Your seats have been selected</td></tr>"
        )
    }

    synchronizeLegendWithMap() {
        this.legendItemTargets.forEach((legendItem) => {
            let legendSeat = legendItem.querySelector("use")

            let renderedOnMap = this.seatTargets.some((seat) => {
                return seat.getAttribute("xlink:href") === legendSeat.getAttribute("xlink:href")
            })

            legendItem.hidden = !renderedOnMap
        })
    }

    openFilters(event) {
        event.preventDefault()
        this.drawerTarget.classList.add('hide')
        this.filtersTarget.classList.add('open')
        this.element.classList.remove('closed')
        this.element.classList.add('open')
    }

    openSeats(event) {
        event.preventDefault()
        this.drawerTarget.classList.add('hide')
        this.cartSummaryTarget.classList.add('open')
        this.element.classList.remove('closed')
        this.element.classList.add('open')
    }

    closeDrawer(event) {
        event.preventDefault()
        this.filtersTarget.classList.remove('open')
        this.cartSummaryTarget.classList.remove('open')
        this.drawerTarget.classList.remove('hide')
        this.element.classList.remove('open')
        this.element.classList.add('closed')
    }


    filterByPrice({ target }) {
        let maximum = target.value

        this.interactiveSeatTargets.map(seat => seat.parentElement).forEach(anchor => {
            anchor.removeAttribute("aria-hidden")
            anchor.removeAttribute("opacity")
        })

        this.filteredSeatsByPrice({ maximum }).map(seat => seat.parentElement).forEach(anchor => {
            anchor.setAttribute("aria-hidden", true)
            anchor.setAttribute("opacity", 0.3)
        })
    }

    filteredSeatsByPrice({ maximum }) {
        maximum = parseInt(maximum) || Infinity

        return this.interactiveSeatTargets.filter((seat) => {
            let price = parseInt(seat.dataset.price)

            return price > maximum
        })
    }

    get selectedSeats() {
        return this.seatTargets.filter(seat => {
            let seatId = getSeatId(seat)

            return this.selectedSeatIds.includes(seatId)
        })
    }

    get selectedSeatIds() {
        return this.cartSummaryItemTargets.map((item) => getSeatId(item))
    }

    get eventsHandler() {
        let touchEventHandler = new TouchEventHandler(this.mapTarget)

        return {
            haltEventListeners: touchEventHandler.eventsListenedFor,

            init: function ({ instance }) {
                touchEventHandler.handleEventsFor(instance)
            },

            destroy: function () {
                touchEventHandler.destroy()
            },
        }
    }
}
